import React, { useRef} from "react"

import useIntersection from "../hooks/useIntersection"

import { Trans } from "gatsby-plugin-react-i18next"

const Title = ({ title }) => {
  const titleRef = useRef();
  const inViewportServices = useIntersection(titleRef, '-20px'); // Trigger if 200px is visible from the element

  let toRender = null;

  if (inViewportServices) {
    toRender =
      <div className="animate__animated animate__backInUp">
        <h2 style={{textTransform: 'unset'}}>
          <Trans>{title || "default title"}</Trans>
        </h2>
        <div className="underline"></div>
      </div>;
  }

  return (
    <div className="section-title" ref={titleRef}>
      {toRender}
    </div>
  );
}

export default Title
