import { useState, useEffect } from 'react'

const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
			if (!element) {
				return () => {};
			}
			
			const observer = new IntersectionObserver(
					([entry]) => {
							// setState(entry.isIntersecting);
							if (entry.isIntersecting) {
								setState(entry.isIntersecting);
								observer.unobserve(element.current);
						}
					}, { rootMargin }
			);

			element.current && observer.observe(element.current);
			return () => {
				if (element.current !== null) observer.unobserve(element.current)
			};
    }, []);

    return isVisible;
};

export default useIntersection;